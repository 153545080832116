.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 100vh;
}

.clock {
  width: 300px;
  height: 300px;
  position: relative;
  margin-bottom: 100px;
}

.bg,
.fg {
  width: 300px;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
}

.hours,
.minutes,
.seconds {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  position: absolute;
  width: 100px;
  height: 8px;
  background: #1c1c1e;
  transform-origin: left;
  border-radius: 6px;
  overflow: hidden;
  transition: all 1s linear;
}

.hours {
  width: 60px;
}
.minutes {
}

.seconds {
  height: 4px;
  background: #fc9304;
}
